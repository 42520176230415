import {
	Navigate,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";
import styles from "./App.module.css";
import { toast } from "react-toastify";
import Gradient from "./assets/gradient.png";
import WebsiteHome from "./pages/Website/WebsiteHome/WebsiteHome";
import Blogs from "./pages/Website/Blogs/Blogs";
import Sound from "./pages/WebApp/Sound/Sound";
import WebAppHome from "./pages/WebApp/WebAppHome/WebAppHome";
import LandingPage from "./pages/Website/LandingPage/LandingPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Community from "./pages/WebApp/Community/Community";
import Calendar from "./pages/WebApp/Calendar/Calendar";
import Shop from "./pages/Website/Shop/Shop";
import ProductDetails from "./pages/Website/ProductDetails/ProductDetails";
import Sustainability from "./pages/Website/Sustainability/Sustainability";
import Collaborators from "./pages/Website/Collaborators/Collaborators";
import AboutUs from "./pages/Website/AboutUs/AboutUs";
import ContactUs from "./pages/Website/ContactUs/ContactUs";
import Forgot from "./pages/Forgot";
import Reset from "./pages/Reset";
import Cart from "./pages/Website/Cart/Cart";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import customInstance from "./api/api_setting/axiosConfig";
import store from "./redux/store";
import { loaderActions } from "./redux/reducers/loader";
import PaymentElement from "./pages/Website/Payment/PaymentElement";
import MyProfile from "./pages/Website/MyProfile/MyProfile";
import Addresses from "./pages/Website/MyProfile/Addresses/Addresses";
import Profile from "./pages/Website/MyProfile/Profile/Profile";
import OrderHistory from "./pages/Website/MyProfile/OrderHistory/OrderHistory";
import PaymentSuccess from "./pages/Website/Payment/PaymentSuccess";
import PaymentFailed from "./pages/Website/Payment/PaymentFailed";
import CustomLinearProgress from "./components/CustomLinearProgress/CustomLinearProgress";
import { useDispatch } from "react-redux";
import { musicUploadActions } from "./redux/reducers/musicUpload";
import { resolutionActions } from "./redux/reducers/resolution";
import "react-phone-input-2/lib/style.css";
import BookBand from "./pages/Website/BookBand/BookBand";
import Latest from "./pages/WebApp/Sound/Latest/Latest";
import MusicAll from "./pages/WebApp/Sound/MusicList/MusicAll";
import MusicArtist from "./pages/WebApp/Sound/MusicList/MusicArtist";
import CustomDesign from "./pages/Website/CustomDesign/CustomDesign";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import Onboarding from "./pages/Website/Onboarding/Onboarding";
import { fetchCart } from "./redux/reducers/cart";
import ShippingPolicy from "./pages/Website/Policies/ShippingPolicy";
import TermsOfService from "./pages/Website/Policies/TermsOfService";
import PrivacyPolicy from "./pages/Website/Policies/PrivacyPolicy";
import { userActions } from "./redux/reducers/user";
import { cartActions } from "./redux/reducers/cart";
import { useSelector } from "react-redux";
// import { Shop } from "@mui/icons-material";

const router = createBrowserRouter([
	{
		path: "/",
		element: <WebsiteHome />,
		children: [
			{
				path: "",
				element: <LandingPage />,
			},
			{
				path: "shippingPolicy",
				element: <ShippingPolicy />,
			},
			{
				path: "termsOfService",
				element: <TermsOfService />,
			},
			{
				path: "privacyPolicy",
				element: <PrivacyPolicy />,
			},
			{
				path: "shop",
				element: <Shop />,
			},
			{
				path: "customDesign",
				element: <CustomDesign />,
			},
			{
				path: "cart",
				element: (
					<PrivateRoute>
						<Cart />
					</PrivateRoute>
				),
			},
			{
				path: "payment",
				element: (
					<PrivateRoute>
						<PaymentElement />
					</PrivateRoute>
				),
			},
			{
				path: "paymentSuccess",
				element: (
					<PrivateRoute>
						<PaymentSuccess />
					</PrivateRoute>
				),
			},
			{
				path: "paymentFailed",
				element: (
					<PrivateRoute>
						<PaymentFailed />
					</PrivateRoute>
				),
			},
			{ path: "band", element: <BookBand /> },
			{
				path: "profile",
				element: (
					<PrivateRoute>
						<MyProfile />
					</PrivateRoute>
				),
				children: [
					{
						path: "",
						element: (
							<PrivateRoute>
								<Profile />
							</PrivateRoute>
						),
					},
					{
						path: "addresses",
						element: (
							<PrivateRoute>
								<Addresses />
							</PrivateRoute>
						),
					},
					{
						path: "orders",
						element: (
							<PrivateRoute>
								<OrderHistory />
							</PrivateRoute>
						),
					},
				],
			},
			{
				path: "product-details",
				element: <ProductDetails />,
			},
			{
				path: "/blogs",
				element: <Blogs />,
			},
			{ path: "/sustainability", element: <Sustainability /> },
			{ path: "/collaborators", element: <Collaborators /> },
			{ path: "/aboutUs", element: <AboutUs /> },
			{ path: "/contact", element: <ContactUs /> },
			{
				path: "/login",
				element: (
					<PublicRoute>
						<Login />
					</PublicRoute>
				),
			},
			{
				path: "/register",
				element: (
					<PublicRoute>
						<Register />
					</PublicRoute>
				),
			},
			{
				path: "/forgot",
				element: (
					<PublicRoute>
						<Forgot />
					</PublicRoute>
				),
			},
			{
				path: "/reset",
				element: (
					<PublicRoute>
						<Reset />
					</PublicRoute>
				),
			},
			{
				path: "/onboarding",
				element: (
					<PublicRoute>
						<Onboarding />
					</PublicRoute>
				),
			},
		],
	},
	{
		path: "/app",
		element: <WebAppHome />,
		children: [
			{
				path: "sound",
				element: <Sound />,
				children: [
					{
						path: "",
						element: <Latest />,
					},
					{
						path: "list",
						element: <MusicAll />,
					},
					{
						path: "artist/:id",
						element: <MusicArtist />,
						loader: () => {
							console.log("LOADING");
							return null;
						},
					},
				],
			},
			{
				path: "community",
				element: (
					<PrivateRoute>
						<Community />
					</PrivateRoute>
				),
			},
			{
				path: "calendar",
				element: <Calendar />,
			},
			{
				path: "",
				element: <Navigate replace to="sound" />,
			},
			{
				path: "*",
				element: <Navigate replace to="sound" />,
			},
		],
	},
]);
// import { Shop } from "@mui/icons-material";

// customInstance.interceptors.request.use((config) => {
//   const user = JSON.parse(localStorage.getItem("AL_USER"));
//   if (user?.hasOwnProperty("accessToken")) {
//     config.headers.authorization = user["accessToken"];
//   }
//   store.dispatch(loaderActions.toggleLoading(true));
//   return config;
// });

function App() {
	const isMobile = useMediaQuery({
		query: "(max-width: 1120px)",
	});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCart());
	}, []);

	useEffect(() => {
		dispatch(
			resolutionActions.updateResolution({
				type: isMobile ? "mobile" : "laptop",
			})
		);
	}, [isMobile]);

	const reduxUser = useSelector((state) => state.user);
	// console.log({ reduxUser });
	const localUser = JSON.parse(localStorage.getItem("AL_USER")) || {};
	// this causes a logout on reload
	// useEffect(() => {
	// 	if (localUser?.accessToken && !reduxUser?.userData?._id) {
	// 		localStorage.removeItem("AL_USER");
	// 		dispatch(userActions.removeUser());
	// 		dispatch(cartActions.emptyCart());
	// 		toast.error("Session Expired. Please Login Again");
	// 	}
	// }, [reduxUser]);

	return <RouterProvider router={router} />;
}

export default App;
